// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  // production: false,
  // baseUrl: "https://syn-as-sgaz-dev-mobileapi-indiaindent.azurewebsites.net/",

  // prod
   production: true,
   baseUrl: "https://syn-as-sgaz-prd-mobileapi-indiaindent.azurewebsites.net/",

  adalConfig : {
    tenant: '06219a4a-a835-44d5-afaf-3926343bfb89',
    // clientId: '19aaec94-8745-4e64-8a2c-8dd6adf5d466',  //Dev
    clientId: '89f21133-11c7-44d2-9cbe-9d27b0641b88',  //prod
  authority:'https://login.microsoftonline.com/06219a4a-a835-44d5-afaf-3926343bfb89',
  scopes: ["api://89f21133-11c7-44d2-9cbe-9d27b0641b88/user_impersonation"],
  redirectUri: window.location.origin + '/',
    postLogoutRedirectUri: window.location.origin + '/',
    endpoints: {
      "[HOME_URL_WEB_API]": "[HOME_WEB_API_GUID]"
    }
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
